* {
  box-sizing: border-box;
}

html {
  height: 100%;
  font-family: 'Roboto';
}

body {
  margin: 0;
  height: 100%;
  min-width: 320px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

#root {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.container {
  width: 100%;
  max-width: 1720px;
  margin: 0 auto;
  /* padding-left: 24px;
  padding-right: 24px; */

  @media (min-width: 1120px) {
    padding-left: 0;
    padding-right: 0;
  }
}